import { render, staticRenderFns } from "./VerificationSelect.vue?vue&type=template&id=466a540f&scoped=true&"
import script from "./VerificationSelect.vue?vue&type=script&lang=js&"
export * from "./VerificationSelect.vue?vue&type=script&lang=js&"
import style0 from "./VerificationSelect.vue?vue&type=style&index=0&id=466a540f&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "466a540f",
  null
  
)

export default component.exports