<template>
  <section class="verification-root">
    <AppSection title="Needed Documents for Operation">
      <template
        #tollbar
      >
        <RoundButton
          class="Withdraw_backBtn"
          withIcon
          iconType="arrowLeft"
          text="back to select verification"
          @click="goBack"
        />
      </template>
      <div
        class="verification-wrapper"
        slot="content"
      >
        <div class="list-description">
          <div class="item-description">
            <div class="item-description-holder">
              <div class="image-holder">
                <img src="@/assets/img/_common/verification-business-description-image-1.svg" alt="">
              </div>
              <div class="right-bar">
                <div class="row-title">
                  <span class="item-number">1</span>
                  <h3>Authorised representative to have:</h3>
                </div>
                <ul>
                  <li>Passport or ID Card</li>
                  <li>Cell phone (for video identification)</li>
                </ul>
                <p>Authorised representative must have single signatory rights</p>
              </div>
            </div>
          </div>
          <div class="item-description">
            <div class="item-description-holder">
              <div class="image-holder">
                <img src="@/assets/img/_common/verification-business-description-image-2.svg" alt="">
              </div>
              <div class="right-bar">
                <div class="row-title">
                  <span class="item-number">2</span>
                  <h3>Company’s documents required to be ready:</h3>
                </div>
                <ul>
                  <li>Commercial register excerpt</li>
                  <li>Memorandum / articles of incorporation / association / registration</li>
                  <li>Shareholders register</li>
                  <li>Directors register</li>
                  <li>Passports of shareholders >25%</li>
                  <li>Passports of Directors</li>
                </ul>
                <p>If either a director or a shareholder of the applicant is a legal entity, the same package is needed until we reach natural persons as directors, shareholders and UBOs of the applicant.</p>
              </div>
            </div>
          </div>
          <div class="item-description">
            <div class="item-description-holder">
              <div class="image-holder">
                <img src="@/assets/img/_common/verification-business-description-image-3.svg" alt="">
              </div>
              <div class="right-bar">
                <div class="row-title">
                  <span class="item-number">3</span>
                  <h3>Please pay attention:</h3>
                </div>
                <ul>
                  <li>Provide a commercial register excerpt containing the following information: active status, directors (signatories) and address of the company</li>
                  <li>Documents are accepted in English</li>
                  <li>If either a director or a shareholder of the applicant is a legal entity, the same package is needed until we reach natural persons as directors, shareholders and UBOs of the applicant</li>
                </ul>
                <h4>Financials might be requested:</h4>
                <ul>
                  <li>Income statement</li>
                  <li>Balance sheet</li>
                </ul>
                <h4>Other documents if applicable:</h4>
                <ul>
                  <li>License</li>
                  <li>AML Policy</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-wrapper">
          <OutlineButton
            class="WalletMethod_copyBtn"
            text="request application"
            fullWidth
            @click="onRequestApplication"
          />
        </div>
      </div>
    </AppSection>
  </section>
</template>

<script>
import AppSection from '@/components/_common/AppSection/AppSection';
import OutlineButton from "@/components/_common/FormElements/OutlineButton/OutlineButton";
import { mapActions } from "vuex";
import RoundButton from '@/components/_common/FormElements/RoundButton/RoundButton';

export default {
  name: "VerificationBusinessDetail",
  components: {
    AppSection,
    OutlineButton,
    RoundButton
  },
  methods: {
    ...mapActions('user', ['onChangeVerificationStep']),
    goBack() {
      this.onChangeVerificationStep(1)
    },
    onRequestApplication() {
      this.onChangeVerificationStep(4)
    },
  }
};
</script>

<style scoped lang="scss">
.list-description {
  padding: 40px 0 0;

  @media screen and (min-width: 768px) {
    position: relative;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      top: 52%;
      transform: translateY(-50%);
      right: -17%;
      background-image: url("~@/assets/img/_common/business-verification-image.svg");
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      width: 1060px;
      height: 828px;
      opacity: .2;
      z-index: 0;
    }
  }
}

.item-description {
  border-bottom: 1px solid rgba(#2AF3F3, 0.1);
  padding: 0 0 24px 20px;
  margin: 0 0 44px;
  z-index: 1;

  @media screen and (min-width: 768px) {
    padding: 0 0 24px 50px;
  }
}

.item-description-holder {
  display: flex;
  align-items: center;
  max-width: 970px;
}

.image-holder {
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
    width: 80px;
    height: 123px;
    margin: 0 43px 0 0;
    flex-shrink: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }
}

.right-bar {
  text-align: left;
  color: #fff;

  .row-title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 0 20px;
  }

  .item-number {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.21);
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    margin: 0 10px 0 0;
  }

  h3 {
    font-size: 24px;
    line-height: 1;
    width: calc(100% - 36px);

    @media screen and (min-width: 1200px) {
      font-size: 32px;
    }
  }

  ul {
    padding: 0 0 0 33px;
    margin: 0 0 16px;

    li {
      position: relative;
      margin: 0 0 10px;
      padding: 0 0 0 16px;
      font-size: 16px;
      line-height: 22px;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 7px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #2AF3F2;
      }
    }
  }

  h4 {
    font-size: 18px;
    line-height: 1;
    margin: 0 0 16px;
  }

  p {
    margin: 0;
    line-height: 17px;
  }
}

.btn-wrapper {
  max-width: 530px;
  margin: 0 auto;
}
</style>