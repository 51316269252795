<template>
  <section>
    <component
      :is="currentVerificationMethod"
    ></component>
  </section>
</template>

<script>
import VerificationSelect from "./VerificationSelect";
import VerificationBusiness from "./VerificationBusiness";
import VerificationPersonal from "./VerificationPersonal";
import VerificationBusinessDetail from "./VerificationBusinessDetail";
import { mapGetters } from "vuex";

export default {
  components: {
    VerificationSelect,
    VerificationBusiness,
    VerificationPersonal,
    VerificationBusinessDetail
  },
  computed: {
    ...mapGetters('user', [ 'getChangeVerificationMethod' ]),
    currentVerificationMethod() {
      switch (this.getChangeVerificationMethod) {
        case 1:
          return 'VerificationSelect';
        case 2:
          return 'VerificationPersonal';
        case 3:
          return 'VerificationBusinessDetail';
        case 4:
          return 'VerificationBusiness';
        default:
          return 1;
      }
    },
  },
};
</script>
<style lang="scss">
  .verification-wrapper {
    min-height: calc(100vh - 183px);
  }
</style>
