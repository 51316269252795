<template>
  <section class="verification-root">
    <AppSection title="Needed Documents for Operation">
      <div
        class="verification-wrapper"
        slot="content"
      >
        <div class="columns-wrapper" v-if="userInfo && userInfo.account_type === 'NOT_VERIFIED'">
          <div class="col" @click="onPersonalBenefits">
            <header class="col__top-bar">
              <div class="col__top-bar-title-row">
                <h3 class="col__title">Personal Benefits</h3>
                <div class="col__image">
                  <img src="@/assets/img/_common/personal-verification-image.svg" alt="">
                </div>
              </div>
              <Button
                text="Proceed as user"
                class="btn btn-bright btn-bright-large min-w-full w-full"
                brightBtn
              >
                <template #icon>
                  <img src="@/assets/icons/icon-round-arrow.svg" alt="">
                </template>
              </Button>
            </header>
            <div class="col__body">
              <div class="decor-box green"></div>
              <ul class="col__body-list">
                <li>High Limits per transaction</li>
                <li>Low fees</li>
                <li>SWIFT, SEPA, VISA/MASTERCARD</li>
                <li>Invoicing</li>
                <li>P2P Transfers with 0% Fee</li>
                <li>Deposit / Withdrawal to EU Banks</li>
                <li>Live Support</li>
              </ul>
            </div>
          </div>
          <div class="col" @click="onBusinessDetail">
            <header class="col__top-bar">
              <div class="col__top-bar-title-row">
                <h3 class="col__title">Business Benefits</h3>
                <div class="col__image">
                  <img src="@/assets/img/_common/business-verification-image.svg" alt="">
                </div>
              </div>
              <Button
                text="Proceed as business"
                class="btn btn-bright btn-bright-large min-w-full w-full"
                brightBtn
              >
                <template #icon>
                  <img src="@/assets/icons/icon-round-arrow.svg" alt="">
                </template>
              </Button>
            </header>
            <div class="col__body">
              <div class="decor-box blue"></div>
              <ul class="col__body-list">
                <li>Quick Start</li>
                <li>CryptoProcessing</li>
                <li>High Limits</li>
                <li>Premium Support 24/7</li>
                <li>SWIFT / SEPA</li>
                <li>Payment HUB</li>
                <li>API & SDK</li>
              </ul>
            </div>
          </div>
        </div>
        <VerificationSucccess v-else />
      </div>
    </AppSection>
  </section>
</template>

<script>
import VerificationSucccess from "./VerificationSucccess";
import AppSection from '@/components/_common/AppSection/AppSection';
import Button from "@/components/_common/FormElements/Button/Button";
import { mapActions, mapState } from "vuex";
export default {
  name: "VerificationSelect",
  components: {
    VerificationSucccess,
    AppSection,
    Button
  },
  computed: {
    ...mapState('user', ['userInfo']),
  },
  methods: {
    ...mapActions('user', ['onChangeVerificationStep']),
    onPersonalBenefits() {
      this.onChangeVerificationStep(2)
    },
    onBusinessDetail() {
      this.onChangeVerificationStep(3)
    }
  }
};
</script>

<style scoped lang="scss">
  .columns-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 30px;

    @media screen and (min-width: 768px) {
      margin: 35px 0 0;
    }

    @media screen and (min-width: 1400px) {
      justify-content: center;
      grid-template-columns: repeat(auto-fit, minmax(300px, 512px));
      grid-gap: 60px;
      margin: 70px 0 0;
    }

    .col {
      transition: border .3s;
      display: flex;
      flex-direction: column;
      border: 2px solid rgba(255, 255, 255, 0.34);
      border-radius: 26px;
      cursor: pointer;
      position: relative;
      z-index: 1;

      &:after {
        content: '';
        position: absolute;
        top: 13px;
        right: -18px;
        background: rgba(255, 255, 255, 0.08);
        height: 92%;
        width: 100%;
        border-radius: 26px;
        z-index: -1;
      }

      &:hover {
        border: 2px solid #2AF3F3;
      }

      &__top-bar {
        padding: 20px 15px;
        position: relative;
        border-bottom: 2px solid rgba(255, 255, 255, 0.34);

        @media screen and (min-width: 768px) {
          padding: 40px 30px;
        }

        @media screen and (min-width: 1400px) {
          padding: 40px 66px;
        }

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background-color: rgba(255, 255, 255, 0.08);
          backdrop-filter: blur(49px);
          border-top-left-radius: 26px;
          border-top-right-radius: 26px;
          overflow: hidden;
        }
        
        .btn {
          position: relative;
          z-index: 1;
        }
      }

      &__top-bar-title-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        text-align: left;
        margin: 0 0 15px;
        position: relative;
        z-index: 1;

        @media screen and (min-width: 1400px) {
          margin: 0 0 25px;
        }
      }

      &__title {
        font-size: 21px;
        line-height: 1;
        color: #fff;
        letter-spacing: 0.04em;
        font-weight: 500;
        margin: 0 10px 0 0;
        width: calc(100% - 130px);

        @media screen and (min-width: 768px) {
          font-size: 24px;
        }

        @media screen and (min-width: 1400px) {
          font-size: 38px;
        }

        @media screen and (min-width: 1900px) {
          font-size: 44px;
        }
      }

      &__image {
        width: 50px;
        height: 50px;
        flex-shrink: 0;

        @media screen and (min-width: 1400px) {
          width: 80px;
          height: 80px;
        }

        @media screen and (min-width: 1900px) {
          width: 120px;
          height: 96px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: scale-down;
        }
      }

      &__body {
        padding: 20px 15px 26px;
        position: relative;
        box-shadow: inset 0 7.26345px 9.07931px rgba(34, 39, 47, 0.14);
        flex-grow: 1;

        @media screen and (min-width: 768px) {
          padding: 40px 30px;
        }

        @media screen and (min-width: 1400px) {
          padding: 40px 66px;
        }

        &:after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: rgba(16, 32, 50, 0.44);
          backdrop-filter: blur(73px);
          border-bottom-left-radius: 26px;
          border-bottom-right-radius: 26px;
          overflow: hidden;
        }

        .decor-box {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          min-width: 200px;
          min-height: 200px;
          opacity: 0.26;
          filter: blur(10px);

          @media screen and (min-width: 768px) {
            min-width: 410px;
            min-height: 285px;
          }

          &.green {
            background-color: #29FD2E !important;
          }

          &.blue {
            background-color: #0F7EF7 !important;
          }
        }
      }

      &__body-list {
        text-align: left;
        font-size: 14px;
        position: relative;
        z-index: 1;
        padding: 0;
        margin: 0;

        @media screen and (min-width: 768px) {
          font-size: 16px;
        }

        @media screen and (min-width: 1400px) {
          font-size: 21px;
        }

        li {
          line-height: 1;
          margin: 0 0 10px;
          padding: 0 0 0 29px;
          position: relative;

          @media screen and (min-width: 768px) {
            margin: 0 0 21px;
          }

          &:last-child {
            margin: 0;
          }

          &:before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50% 50%;
            background-image: url('~@/assets/icons/icon-list-verification-block.svg');
            width: 19px;
            height: 19px;
          }
        }
      }
    }
  }
</style>