<template>
  <section class="verification-root">
    <AppSection title="Verification">
      <div
        class="verification-wrapper"
        slot="content"
      >
        <div
          class="relative py-[200px]"
          key="loader"
          v-if="_userModulePending"
        >
          <Loader />
        </div>
        <div key="container" v-else>
          <div
            id="verification_sumbsub"></div>
        </div>
      </div>
    </AppSection>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AppSection from '@/components/_common/AppSection/AppSection';
import Loader from '@/components/_common/Loader/Loader';
import snsWebSdk from '@sumsub/websdk';

export default {
  name: "VerificationPersonal",
  components: {
    AppSection,
    Loader,
  },
  computed: {
    ...mapState('user', ['token', 'userInfo', '_userModuleLoaded', '_userModulePending']),
  },
  watch: {
    _userModuleLoaded: {
      handler(newVal) {
        if (newVal) this.prepareUserSumsubToken();
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('user', ['apiUpdateUserSumsubToken']),
    launchSumSubSdk(token) {
      const { email } = this.userInfo;
      const accessToken = token;
      const applicantEmail = email;
      const snsWebSdkInstance = snsWebSdk
        .init(accessToken, getNewAccessToken => {
          getNewAccessToken(accessToken);
        })
        .withConf({
          lang: 'en',
          email: applicantEmail,
          onMessage: (type, payload) => {
            console.log('WebSDK onMessage', type, payload);
          },
          uiConf: {
            customCss: 'https://kauri.finance/Verification.css',
          },
          onError: error => {
            console.log('WebSDK onError', error);
          },
        })
        .build();
      snsWebSdkInstance.launch('#verification_sumbsub');
    },
    prepareUserSumsubToken() {
      this.apiUpdateUserSumsubToken({
        verification_flow: 'KYC_test',
      }).then(token => this.launchSumSubSdk(token));
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
.verification-root {
  height: $page-container-height;
}
</style>