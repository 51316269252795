<template>
  <div class="pt-[55px]">
    <img src="../../../../assets/img/auth/verified-user.svg" alt="Verified_user_image" class="m-auto" />
    <div class="font-bold text-[21px] leading-[150%] text-gray-600 dark:text-white uppercase mb-[4px]">Verification Success</div>
    <div class="tracking-[0.04em] text-[14px] leading-[16px]">Dear user! You have been successfully verified!</div>
  </div>
</template>

<script>
export default {
  name: "VerificationSucccess",
};
</script>